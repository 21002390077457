<template>
  <div v-if="isLoading">
    <aom-skeleton-loader />
  </div>
  <b-card v-else-if="survey">
    <div class="d-flex justify-content-between">
      <h4>{{ surveyTitle }}</h4>
      <div class="d-flex align-items-center">
        <b-button
          variant="primary"
          class="btn-icon mb-2 mr-1"
          :disabled="!selectedParticipants.length"
          @click="sendReminder"
        >
          Send Reminder {{ `(${selectedParticipants.length})` }}
        </b-button>
        <b-dropdown
          variant="link"
          no-caret
          toggle-class="p-0 mb-2"
          right
        >
          <template #button-content>
            <b-button
              variant="primary"
              class="btn-icon"
            >
              Export
            </b-button>
          </template>

          <b-dropdown-item @click="onDownloadSurvey('csv')">
            <span class="align-middle ml-50">Export CSV</span>
          </b-dropdown-item>

          <b-dropdown-item @click="onDownloadSurvey('xlsx')">
            <span class="align-middle ml-50">Export XLSX</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <surveys-result
      ref="surveysResult"
      view-mode="champion"
      @selected-participants="setSelectedParticipants"
    />
  </b-card>
</template>

<script>
import { BDropdown, BDropdownItem, BButton, BCard } from "bootstrap-vue";
import SurveysResult from "@/views/components/forms/surveys/SurveysResult.vue";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { BE_API_URL } from "@/constants/app";
import { formsService } from "@/services";
import { makeErrorToast } from "@/libs/utils";

export default {
  components: {
    SurveysResult,
    BDropdown,
    BDropdownItem,
    BButton,
    BCard,
    AomSkeletonLoader
  },
  data() {
    return {
      isLoading: false,
      survey: null,
      selectedParticipants: []
    };
  },
  computed: {
    surveyTitle() {
      if (this.survey) {
        return this.survey.translations[0].title;
      }
      return '';
    },
  },
  created() {
    this.loadSurvey();
  },
  methods: {
    async loadSurvey() {
      try {
        this.isLoading = true;
        // Load survey detail
        const surveyResponse = await formsService.getProgramSurvey(
          this.$route.params.id,
          this.$route.params.survey
        );
        this.survey = surveyResponse.data;
      } catch (e) {
        console.log(e);
        const { data } = e.response;
        this.$toast(makeErrorToast(data.message));
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async onDownloadSurvey(type) {
      const dowloadLink = `${BE_API_URL}/programs/${this.$route.params.id}/surveys/${this.$route.params.survey}/results/download/${type}`;
      window.document.location = dowloadLink;
    },
    setSelectedParticipants(participants) {
      this.selectedParticipants = participants;
    },
    async sendReminder() {
      this.$bvModal
        .msgBoxConfirm(
          "Do you want to send these participants an email reminding them to complete this survey?",
          {
            title: "Send Reminder?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Send Reminder",
            cancelTitle: "Cancel",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async value => {
          if (value) {
            try {
              const users = this.selectedParticipants.map(p => ({id: p.participantId, userFormId: p.userFormId}));
              await formsService.sendMultiParticipantsReminder(
                this.$route.params.id,
                this.$route.params.survey,
                { users }
              );
              await this.$refs.surveysResult.loadItems();
            } catch (e) {
              this.$log.error(e);
              this.$toast(
                makeErrorToast("Something went wrong. Can't send reminder")
              );
            }
          }
        });
    }
  },
};
</script>